<section class="p-4 border-1 border-outline flex flex-column">
  <div class="flex flex-column align-items-center row-gap-3">
    <fa-icon [icon]="faCircleCheck" size="7x" class="text-main"></fa-icon>
    <h1 class="font-light text-4xl">Job Created Successfully!</h1>
    <p class="text-center text-sm max-w-40rem">
      You have successfully created a sample job. Once you create a verified Roofing Passport account, a takeoff report
      will be generated within 2-4 hours after your job is submitted for processing.
    </p>
    <p class="text-center text-sm">Click "Try Again" to return to the beginning of the demo and start over.</p>
  </div>
  <div class="flex justify-content-between mt-5">
    <button pButton class="default outline" (click)="tryAgain()">Try again</button>
    <button pButton (click)="downloadTakeoffReport()" *ngIf="DEMO_FEATURE_FLAGS.DOWNLOAD_TAKEOFF_REPORT">
      <fa-icon [icon]="faDownload"></fa-icon>
      Download takeoff report
    </button>
  </div>
  <alert
    *ngIf="DEMO_FEATURE_FLAGS.DOWNLOAD_TAKEOFF_REPORT"
    color="main"
    [icon]="faCircleInfo"
    label="About takeoff reports"
    description="This sample takeoff report is available instantly because it was pre-generated using sample data. In real use cases, takeoff reports take between 2-4 hours to generate, depending on the data providers involved."
    styleClass="my-4"
  ></alert>
</section>
<footer
  class="border-1 border-outline p-4 border-top-none flex justify-content-center bg-main text-white align-items-center"
>
  <img src="assets/img/logo/rp-white-iso.png" alt="Roofing Passport" class="rp-logo" />
  <div class="ml-6 flex flex-column">
    <h2 class="text-2xl">Get Started Today</h2>
    <p class="text-sm max-w-32rem mt-2">
      Learn more about the benefits of Roofing Passport and how to get started with an account.
    </p>
    <a
      href="https://industrial.sherwin-williams.com/na/us/en/coil-extrusion/service-programs/metalroofing/roofing-passport.html"
      target="_blank"
      rel="noopener noreferrer"
      pButton
      class="w-max border-none bg-main text-white border-1 border-white mt-3"
      >Learn More</a
    >
  </div>
</footer>
