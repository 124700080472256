import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { Checkbox } from "primeng/checkbox";
import { BehaviorSubject, Observable, ReplaySubject, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { ConfirmService } from "src/app/core/confirm/confirm.service";
import { Manufacturer } from "src/app/core/data/models/AppInitializationData";
import { AdminService } from "src/app/shared/services/admin.service";
import { AppState } from "src/app/shared/services/app-state";

@Component({
  selector: "app-admin-settings",
  templateUrl: "./admin-settings.component.html",
  styleUrls: ["./admin-settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminSettingsComponent implements OnInit, OnDestroy {
  @Output() onCancel = new EventEmitter();

  private destroyed$ = new ReplaySubject(1);
  busy: Subscription;
  errorMessage: string;
  isRPAdmin = this.appState.isAdminOrRPAdmin;
  profile$ = this.appState.profile$;
  checked = true;
  private _manufacturers: BehaviorSubject<Manufacturer[]> = new BehaviorSubject<Manufacturer[]>(
    this.appState.isRPAdmin ? this.appState.managedManufacturers : this.appState.profileManufacturer
  );
  public manufacturers: Observable<Manufacturer[]> = this._manufacturers.asObservable();
  private lastId = "";
  manufacturerId = new UntypedFormControl("");
  manufacturerForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private appState: AppState,
    private adminService: AdminService,
    private confirmService: ConfirmService,
    private messageService: MessageService,
    private router: Router
  ) {}

  get name() {
    return this.manufacturerForm.get("name");
  }

  get roofingWRXCompanyId() {
    return this.manufacturerForm.get("roofingWRXCompanyId");
  }

  get isTestCompany() {
    return this.manufacturerForm.get("isTestCompany");
  }

  ngOnInit(): void {
    this.setupForm();
    this.patchForm(this.appState.currentProfile.manufacturer);

    this.appState.profile$.subscribe((profile) => {
      this._manufacturers.next(
        this.appState.isRPAdmin ? this.appState.managedManufacturers : this.appState.profileManufacturer
      );

      this.onDropdownChange(this.appState.currentProfile.manufacturer.id, true);
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  setupForm() {
    this.manufacturerForm = this.fb.group({
      manufacturerId: [0],
      name: ["", [Validators.required, Validators.maxLength(100)]],
      roofingWRXCompanyId: [0, Validators.pattern(/\d+/)],
      isTestCompany: [false],
    });
  }

  patchForm(manufacturer: Manufacturer) {
    this.lastId = manufacturer?.id.toString() ?? "";
    this.manufacturerForm.patchValue({
      manufacturerId: manufacturer?.id,
      name: manufacturer?.name ?? "",
      roofingWRXCompanyId: manufacturer?.roofingWRXCompanyId,
      isTestCompany: manufacturer?.isTestCompany || false,
    });

    this.manufacturerForm.markAsPristine();
    this.manufacturerForm.markAsUntouched();

    if (manufacturer) {
      this.manufacturerForm.markAllAsTouched();
    }
  }

  onManufacturerChanged(selectedManufacturerId) {
    this.onDropdownChange(parseInt(selectedManufacturerId.value));
  }

  onDropdownChange(selectedManufacturerId: number, forceChange: boolean = false) {
    if (parseInt(this.lastId) !== selectedManufacturerId) {
      const manufacturer = this._manufacturers.value.find((m) => m.id == selectedManufacturerId);

      if (forceChange) {
        this.patchForm(manufacturer);
      }

      if (this.manufacturerForm.dirty && !forceChange) {
        this.confirmService.confirm(
          "Changes Detected",
          "Any unsaved changes will be lost, are you sure you want to switch to another manufacturer?",
          "pi pi-question-circle",
          () => {
            this.patchForm(manufacturer);
          },
          () => {
            this.manufacturerId.setValue(this.lastId);
          }
        );
      } else {
        this.patchForm(manufacturer);
      }
    }
  }

  save() {
    let settings: Manufacturer = {
      id: this.manufacturerId.value,
      name: this.name.value,
      roofingWRXCompanyId: this.roofingWRXCompanyId.value,
      isTestCompany: this.isTestCompany.value,
    };

    if (this.roofingWRXCompanyId.dirty && !this.roofingWRXCompanyId.value) {
      settings.roofingWRXCompanyId = null;
    }

    settings.id = +this.lastId;

    this.adminService
      .updateCompany(settings)
      .pipe(
        map((x: any) => {
          //response is being sent back with error object attached.
          if (!x.error?.message && x.manufacturer) {
            this._manufacturers.next(
              this.appState.isRPAdmin ? this.appState.managedManufacturers : this.appState.profileManufacturer
            );
            this.manufacturerId.setValue(x.manufacturer.id);
            this.messageService.add({
              severity: "success",
              summary: "Success",
              detail: "Manufacturer setting successfully updated.",
            });
            this.manufacturerForm.markAsPristine();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: x.error,
            });
          }
        })
      )
      .subscribe();
  }

  returnToProjectList() {
    this.router.navigate(["../jobs"]);
  }
}
