import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {of} from "rxjs";
import {catchError, tap, finalize} from "rxjs/operators";
import {SmartBuildProject} from "src/app/core/data/models/SmartBuildProject";
import {JobStatusEnum, JobStatusIdEnum} from "src/app/core/enums/job-status.enum";
import {SpinnerService} from "src/app/core/spinner/spinner.service";
import {SmartBuildService} from "src/app/shared/services/smartbuild.service";
import {SidebarService} from "src/app/shared/services/sidebar.service";
import {MessageService} from "primeng/api";
import {Table} from "primeng/table";
import {OrderService} from "src/app/shared/services/order.service";
import {JobCounts} from "src/app/core/data/models/JobCounts";
import {ApiEndpoints} from "src/app/shared/constants/api-endpoints";
import {AppState} from "src/app/shared/services/app-state";
import {AuthContext, AuthenticationService} from "src/app/shared/services/authentication.service";
import {JobComment} from "src/app/core/store/app.models";
import {OrderSequence} from "src/app/core/data/models/CreateOrderRequest";
import {
    faPlus,
    faCheckCircle,
    faRulerCombined,
    faHouseCircleCheck,
    faStore,
    faArchive,
    faEye,
    faEllipsisV,
    faDollar,
    faTrash,
    faReply,
    faDownload,
    faPaperPlane,
    faExclamation,
    faTimes,
    faInfo,
  faArrowsRotate,
} from "@fortawesome/free-solid-svg-icons";
import {ISortableRequest} from "src/app/interfaces/sortable-request";
import { WindowRefService} from "src/app/shared/helpers/window-ref.service";

@Component({
    selector: "job-list",
    templateUrl: "./job-list.component.html",
    styleUrls: ["./job-list.component.scss"],
})
export class JobListComponent implements OnInit, OnDestroy {
    constructor(
        private smartbuildService: SmartBuildService,
        private spinner: SpinnerService,
        private sidebarService: SidebarService,
        private messageService: MessageService,
        private orderService: OrderService,
        private http: HttpClient,
        private appState: AppState,
        private authService: AuthenticationService,
        private windowRefService: WindowRefService
    ) {
    }

    ngOnDestroy(): void {
        this.sidebarService.closeSidebar();
    }

    jobCounts?: JobCounts = {
        created: 0,
        pendingTakeoff: 0,
        pendingDimensions: 0,
        completed: 0,
        informationRequired: 0,
        canceled: 0,
        failed: 0,
    };
    jobs: any = [];
    files: any = [];
    jobId?: number;
    smartBuildId?: number;
    canOpenOnSmartBuild: boolean = false;
    comments: JobComment[] = [];
    jobStatuses = JobStatusEnum;
    selectedJob?: any;
    orderStatusId: number;

    pageSize = 10;
    totalJobsCount = 10;
    page = 1;
    sortBy: ISortableRequest = {
        SortBy: 4,
        SortDescending: true,
    };
    display: boolean = false;
    contentPublished: boolean = true;
    isSidebarOpen: boolean = false;
    tabIndex = 0;
    showAllCompanyJobs: boolean = false;

    eagleViewIcon: string = "assets/img/icons/source_ev.png";
    roofingWRXIcon: string = "assets/img/icons/source_rw.png";
    xmlIcon: string = "assets/img/icons/source_xml.png";
    areProjectsFilteredApplied: boolean = false;
    faPlus = faPlus;
    faCheckCircle = faCheckCircle;
    faRulerCombined = faRulerCombined;
    faHouseCircleCheck = faHouseCircleCheck;
    faStore = faStore;
    faArchive = faArchive;
    faEye = faEye;
    faEllipsisV = faEllipsisV;
    faDownload = faDownload;
    faDollar = faDollar;
    faReply = faReply;
    faTrash = faTrash;
    faPaperPlane = faPaperPlane;
    faExclamation = faExclamation;
    faTimes = faTimes;
    faInfo = faInfo;
    faArrowsRotate = faArrowsRotate;

    @ViewChild("sidebarContent") sidebarContent!: TemplateRef<any>;
    @ViewChild("jobsTable") jobsTable: Table;

    canDisplayComments: boolean = false;

    ngOnInit(): void {
        this.getAllJobs();
        this.appState.setAdminMenuItems();
        this.appState.clearFilter.subscribe((data) => {
            if (data === true) {
                this.clearFilters();
            }
        });
    }

    getAllJobs(orderSequence?: string, pageIndex?: number) {
        this.spinner.show();
        this.smartbuildService.getJobCountsByStatus(this.showAllCompanyJobs).subscribe((data) => {
            const jobs: Map<number, number> = data;
            const statusMapping = {
                [JobStatusIdEnum["Job Created"]]: "created",
                [JobStatusIdEnum["TakeOff Report Generating"]]: "pendingTakeoff",
                [JobStatusIdEnum["Dimension Data Generating"]]: "pendingDimensions",
                [JobStatusIdEnum["TakeOff Report Completed"]]: "completed",
                [JobStatusIdEnum["Information Required"]]: "informationRequired",
                [JobStatusIdEnum["Job Canceled"]]: "canceled",
                [JobStatusIdEnum["Job Failed"]]: "failed",
            };

            Object.keys(jobs).forEach((key) => {
                const statusId = parseInt(key);
                const jobCountProperty = statusMapping[statusId];
                if (jobCountProperty) {
                    this.jobCounts[jobCountProperty] = jobs[statusId];
                }
            });
        });

        if (pageIndex >= 1) {
            this.page = pageIndex;
        }

        orderSequence ? (this.orderStatusId = JobStatusIdEnum[orderSequence]) : null;

        this.smartbuildService
            .getProjects("false", this.page, this.pageSize, this.orderStatusId, this.showAllCompanyJobs, this.sortBy)
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    this.messageService.add({
                        severity: "error",
                        summary: err.error?.title || "Error",
                        detail: err.error?.detail || "An error occurred while fetching jobs.",
                    });
                    return of([] as SmartBuildProject[]);
                }),
                tap((data: SmartBuildProject[]) => {
                    this.jobs = data["jobs"];
                    this.totalJobsCount = data["totalJobsCount"];
                    if (orderSequence) {
                        this.areProjectsFilteredApplied = true;
                    }
                }),
                finalize(() => {
                    this.spinner.hide();
                })
            )
            .subscribe();
    }

    getTooltip(job: SmartBuildProject): string {
        const requiresSmartBuild = this.requiresSmartBuild(job);
        const requiresRoofingWrx = this.requiresRoofingWrx(job);

        if (requiresSmartBuild && requiresRoofingWrx) {
            return "SmartBuild and Roofing WRX are not enabled. Please integrate with both to view this job.";
        }

        if (requiresSmartBuild) {
            return "SmartBuild is not enabled. Please integrate with SmartBuild to view this job.";
        }

        if (requiresRoofingWrx) {
            return "Roofing WRX is not enabled. Please integrate with Roofing WRX to view this job.";
        }

        return null;
    }

    disableRow(job: SmartBuildProject): boolean {
        return this.requiresSmartBuild(job) || this.requiresRoofingWrx(job);
    }

    requiresSmartBuild(job: SmartBuildProject): boolean {
        const smartBuildSequences = [
            OrderSequence.BlueprintToSmartBuild,
            OrderSequence.EagleViewToSmartBuild,
            OrderSequence.XMLToSmartBuild,
        ];

        if (!smartBuildSequences.includes(job.sequenceId)) {
            return false;
        }

        return (
            this.appState.currentProfile.authorizations.find((auth) => auth.context === AuthContext.SmartBuild)?.status !==
            "Valid"
        );
    }

    requiresRoofingWrx(job: SmartBuildProject): boolean {
        const roofingWRXSequences = [
            OrderSequence.BlueprintToSmartBuild,
            OrderSequence.BlueprintToRoofingWRX,
            OrderSequence.EagleViewToRoofingWRX,
            OrderSequence.XMLToRoofingWRX,
        ];

        if (!roofingWRXSequences.includes(job.sequenceId)) {
            return false;
        }

        return (
            this.appState.currentProfile.authorizations.find((auth) => auth.context === AuthContext.RoofingWRX)?.status !==
            "Valid"
        );
    }

    getJobStatusColor(jobStatusId: number): string {
        return this.jobStatuses[JobStatusIdEnum[jobStatusId]];
    }

    getJobStatus(jobStatusId: number): string {
        return JobStatusIdEnum[jobStatusId];
    }

    sortByColumn(column: string) {
        switch (column) {
            case "Number":
                this.sortBy.SortBy = 6;
                break;
            case "Name":
                this.sortBy.SortBy = 2;
                break;
            case "Address":
                this.sortBy.SortBy = 8;
                break;
            case "Status":
                this.sortBy.SortBy = 10;
                break;
            case "Date":
                this.sortBy.SortBy = 4;
                break;
            default:
                break;
        }
        this.areProjectsFilteredApplied = true;
        this.sortBy.SortDescending = !this.sortBy.SortDescending;
        this.getAllJobs();
    }

    openSidebar(job: any) {
        this.selectedJob = job;
        this.canDisplayComments =
            job.sequenceId === OrderSequence.BlueprintToSmartBuild || job.sequenceId === OrderSequence.BlueprintToRoofingWRX;

        this.getOrderDetails(job.id);

        this.sidebarService.openSidebar(this.sidebarContent);
    }

    getOrderDetails(orderId: string) {
        this.orderService
            .getOrderDetails(orderId)
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    this.messageService.add({
                        severity: "error",
                        summary: err.error?.title || "Error",
                        detail: err.error?.detail || "An error occurred while fetching job details",
                    });
                    return null;
                }),
                tap((job: any) => {
                    if (job) {
                        this.files = job.files;
                        this.comments = job.comments;
                        this.jobId = job.id;
                        this.canOpenOnSmartBuild = job.canOpenOnSmartBuild;
                        this.smartBuildId = job.smartBuildId;
                    }
                })
            )
            .subscribe();
    }

    publish() {
        this.contentPublished = !this.contentPublished;
    }

    onPageChange(page: number) {
        this.page = page;
        this.getAllJobs();
    }

    onPageSizeChange(pageSize: number) {
        this.page = 1;
        this.pageSize = pageSize;
        this.getAllJobs();
    }

    getTotalPages(jobsCount: number): number {
        return Math.ceil(this.totalJobsCount / this.pageSize);
    }

    submitComment(comment: string) {
        if (!this.selectedJob?.id) {
            this.messageService.add({
                severity: "error",
                summary: "Error",
                detail: "No job selected. Unable to submit comment.",
            });
            return;
        }

        this.orderService
            .updateRoofingWRXJobComments(this.selectedJob.id, {comments: comment})
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    this.messageService.add({
                        severity: "error",
                        summary: err.error?.title || "Error",
                        detail: err.error?.detail?.error_description || "An error occurred while submitting the comment.",
                    });
                    return of(null);
                }),
                tap((response) => {
                    if (response) {
                        if (this.selectedJob) {
                            this.getOrderDetails(this.selectedJob.id);
                        }
                    }
                })
            )
            .subscribe();
    }

    downloadFile(fileName: string): void {
        if (!this.selectedJob) {
            this.messageService.add({
                severity: "error",
                summary: "Error",
                detail: "No job selected.",
            });
            return;
        }

        const params = {
            orderId: this.selectedJob.id,
            fileName: fileName,
        };

        const endpoint = ApiEndpoints.downloadJobFile(params);
        const url = `${this.appState.configs.ServicesBaseUrl}${endpoint}`;

        const headers = new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            Authorization: `Bearer ${this.authService.accessToken()}`,
        });

        this.http.get(url, {headers, responseType: "blob"}).subscribe(
            (response: Blob) => {
                const blobUrl = window.URL.createObjectURL(response);
                const link = document.createElement("a");
                link.href = blobUrl;
                link.download = fileName;
                link.click();
                window.URL.revokeObjectURL(blobUrl);
            },
            (error) => {
                this.messageService.add({
                    severity: "error",
                    summary: "Download Error",
                    detail: error.message || "Error downloading file",
                });
            }
        );
    }

    sourceIcon(sequenceId: number): string {
        if (this.isXMLJob(sequenceId)) {
            return this.xmlIcon;
        }
        if (this.isEagleViewJob(sequenceId)) {
            return this.eagleViewIcon;
        }
        if (this.isRoofingWRXJob(sequenceId)) {
            return this.roofingWRXIcon;
        }
    }

    isXMLJob(sequenceId: number): boolean {
        return sequenceId === OrderSequence.XMLToSmartBuild;
    }

    isEagleViewJob(sequenceId: number): boolean {
        return sequenceId === OrderSequence.EagleViewToSmartBuild;
    }

    isRoofingWRXJob(sequenceId: number): boolean {
        return !this.isXMLJob(sequenceId) && !this.isEagleViewJob(sequenceId);
    }

    formatJobAddress(job: any): string {
        if (!job || !job.address) {
            return "";
        }
        const {street, city, state, zip} = job.address;
        return [street, city, state, zip].filter(Boolean).join(", ");
    }

    areFiltersApplied(): boolean {
        const hasFilters = this.areProjectsFilteredApplied === true || (this.jobsTable && !!this.jobsTable.sortField);
        return hasFilters;
    }

    clearFilters() {
        if (this.jobsTable) {
            this.jobsTable.filters = {};
            this.jobsTable.sortField = undefined;
            this.jobsTable.reset();
        }
        this.areProjectsFilteredApplied = false;
        this.sortBy = {
            SortBy: 4,
            SortDescending: true,
        };
        this.orderStatusId = null;
        this.getAllJobs();
    }

    closeSidebar(): void {
        this.sidebarService.closeSidebar();
    }

    openJobInSmartBuild(job: any): void {
        if (!this.canOpenOnSmartBuild) return;

        this.smartbuildService.getSmartBuildToken().subscribe((token) => {
            const smartBuildJobEditUrlBase = `${this.appState.configs.SmartBuildPath}Framer/EditWithToken/`;

      this.windowRefService.nativeWindow.open(`${smartBuildJobEditUrlBase}${this.smartBuildId}?token=${token}`);
    });
  }

    switchHeaders({index}) {
        this.showAllCompanyJobs = index === 1;
        this.getAllJobs();
    }

  refresh() {
    this.getAllJobs();
  }
}
