import { Component, EventEmitter, OnInit, Output, ViewChild, Inject, LOCALE_ID } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { ColumnApi, GridApi } from "ag-grid-community";
import { BehaviorSubject, Observable, ReplaySubject, Subscription, of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { AppConstants } from "src/app/shared/constants/app-constants";
import { takeUntil } from "rxjs/operators";
import { ManufacturerProfile } from "src/app/core/data/models/AppInitializationData";
import { ManufacturerInvitation } from "src/app/core/data/models/ManufacturerInvitation";
import { CredentialParams } from "src/app/core/data/models/CredentialParams";
import { HttpErrorResponse } from "@angular/common/http";
import { MessageService, SortEvent } from "primeng/api";
import * as moment from "moment";
import { SpinnerService } from "src/app/core/spinner/spinner.service";
import { Table } from "primeng/table";
import { ConfirmService } from "src/app/core/confirm/confirm.service";
import { AdminService } from "src/app/shared/services/admin.service";
import { AppState } from "src/app/shared/services/app-state";
import { LocalStorageService } from "src/app/shared/services/local-storage.service";
import { ProfileService } from "src/app/shared/services/profile.service";
import {
  faDownload,
  faPen,
  faEllipsisV,
  faPlus,
  faEnvelope,
  faAsterisk,
  faToggleOn,
  faToggleOff,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { CompanyService } from "../../../shared/services/company.service";
import { GetCompanyNamesResponse } from "../../../core/data/models/GetCompanyNamesResponse";
import { GetUsersResponse } from "../../../core/data/models/GetUsersResponse";
import { ISortableRequest } from "src/app/interfaces/sortable-request";

@Component({
  selector: "app-admin-users",
  templateUrl: "./admin-users.component.html",
  styleUrls: ["./admin-users.component.scss"],
})
export class AdminUsersComponent implements OnInit {
  faDownload = faDownload;
  faPen = faPen;
  faEllipsisV = faEllipsisV;
  faPlus = faPlus;
  faEnvelope = faEnvelope;
  faAsterisk = faAsterisk;
  faToggleOn = faToggleOn;
  faToggleOff = faToggleOff;
  faTrash = faTrash;

  usersTableColumns = ["Name", "Company", "Eagleview", "Roofing WRX", "XML", "Last seen", "Status"];

  @ViewChild("clearContainer") clearContainer: HTMLElement;
  @Output() onCancel = new EventEmitter();
  durationInSeconds = 3;
  userForm: FormGroup;
  busy: Subscription;
  exportErrorMessage: string;
  errorMessage: string;
  selectedUsers: any[] = [];
  isRPAdmin = this.appState.isAdminOrRPAdmin;
  isAdmin = this.appState.isAdmin;
  profile$ = this.appState.profile$;
  multiSortKey = "ctrl";
  activeManufacturers = [];
  users: GetUsersResponse[] = [];
  selectedRows: any = [];
  credentialParams: CredentialParams[] = [];
  dataReady = false;
  dataError = false;
  dataErrorMessage;
  invitationEmail;
  private gridApi: GridApi;
  private gridColumnApi: ColumnApi;
  private destroyed$ = new ReplaySubject(1);
  public rowSelection: "single" | "multiple" = "multiple";
  viewUserDetailsDialog: boolean;
  editUserDialog: boolean;
  selectedUser: any;
  private _validUserForNewInvitation: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public resendButton: Observable<boolean> = this._validUserForNewInvitation.asObservable();
  fullNameFilterValue: string;
  companyNameFilterValue: string;
  private _isClearVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isClearVisible: Observable<boolean> = this._isClearVisible.asObservable();
  private _companies: BehaviorSubject<GetCompanyNamesResponse[]> = new BehaviorSubject<GetCompanyNamesResponse[]>([]);
  public companies: Observable<GetCompanyNamesResponse[]> = this._companies.asObservable();
  public companyId: number = this.appState.currentProfile.manufacturer.id;

  protected pageSize: number = 10;
  protected totalCount: number = 10;
  protected totalPages: number = 1;
  protected pageNumber: number = 1;
  sortBy: ISortableRequest =
    {
      SortBy: 4,
      SortDescending: true
    };

  sortByName: boolean = false;
  sortByCompanyName: boolean = false;
  sortByEagleView: boolean = false;
  sortByRoofingWRX: boolean = false;
  sortByXMLOrders: boolean = false;

  constructor(
    private appState: AppState,
    private adminService: AdminService,
    private fb: FormBuilder,
    private localStorageService: LocalStorageService,
    private profileService: ProfileService,
    public spinner: SpinnerService,
    private confirmService: ConfirmService,
    private messageService: MessageService,
    private companyService: CompanyService,
    private router: Router,
    @Inject(LOCALE_ID) public locale: string
  ) {
  }

  ngOnInit(): void {
    this.initializeForm();

    this.appState.profile$.pipe(
      takeUntil(this.destroyed$))
      .subscribe(() => {
        this.getCompanyUsers();

        if (this.appState.isRPAdmin) {
          this.companyService.getCompanyNames()
            .pipe(takeUntil(this.destroyed$))
            .subscribe((companyNames: GetCompanyNamesResponse[]) => {
              this._companies.next(companyNames);
              this.companyId = this.appState.currentProfile.manufacturer.id;
              this.patchForm();
            });
        } else {
          this._companies.next(this.appState.profileManufacturer);
          this.companyId = this.appState.currentProfile.manufacturer.id;
          this.patchForm();
        }
      });
  }

  onPageChange(page: number) {
    this.pageNumber = page;
    this.getCompanyUsers();
  }

  onPageSizeChange(pageSize: number) {
    this.pageSize = pageSize;
    this.pageNumber = 1;
    this.getCompanyUsers();
  }


  onHeaderClick(column: string) {
    switch (column) {
      case 'Name':
        this.sortBy.SortBy = 2;
        break;
      case 'Company':
        this.sortBy.SortBy = 1;
        break;
      case 'Date':
        this.sortBy.SortBy = 4;
        break;
      case 'Status':
        this.sortBy.SortBy = 10;
        break;
      default:
        break;
    }
    this.sortBy.SortDescending = !this.sortBy.SortDescending;
    this.getCompanyUsers();
  }

  getTotalPages(): number {
    return Math.ceil(this.totalCount / this.pageSize);
  }

  initializeForm() {
    this.setupForm();
    this.getCompanyUsers();
  }

  getOrderLabel(count: number): string {
    return count === 1 ? "Order" : "Orders";
  }

  setupForm() {
    this.userForm = this.fb.group({
      companyId: this.companyId,
      userId: 0,
      email: "",
      firstName: "",
      lastName: "",
      companyName: "",
      companyAddress: "",
      phoneNumber: "",
      role: "",
      fullNameFilterValue: "",
      companyNameFilterValue: "",
    });
  }

  patchForm(user: any = null) {
    if (user === null) {
      this.userForm.patchValue({
        ...this.userForm.value,
        companyId: this.companyId,
      });
    } else {
      this.userForm.patchValue({
        companyId: this.companyId,
        userId: user.id ? this.selectedUser.id : 0,
        email: user.email ? user.email : "",
        firstName: user.firstName ? user.firstName : "",
        lastName: user.lastName ? user.lastName : "",
        companyName: user.companyName ? user.companyName : "",
        companyAddress: user.companyAddress ? user.companyAddress : "N/A",
        phoneNumber: user.phoneNumber ? user.phoneNumber : "N/A",
        role: user.role ? user.role : "",
      });
    }

    this.viewUserDetailsDialog = user !== null;
  }

  exportUsers() {
    this.exportErrorMessage = null;
    this.busy = this.adminService
      .downloadUserReport(this.companyId)
      .pipe(
        tap(() => {
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Report has been downloaded.",
          });
        }),
        catchError((error: HttpErrorResponse) => {
          this.exportErrorMessage = error.error ?? AppConstants.RoofingPassportCommunicationError;
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: this.exportErrorMessage,
          });
          return of(null);
        })
      )
      .subscribe();
  }

  resendRPInvitation() {
    const invite: ManufacturerInvitation = {
      companyId: this.companyId,
      firstName: this.selectedUser.firstName,
      lastName: this.selectedUser.lastName,
      companyName: this.selectedUser.companyName,
      contactPhoneNumber: this.selectedUser.contactPhoneNumber,
      email: this.selectedUser.email,
    };

    this.profileService
      .resendRPInvitation(invite)
      .pipe(
        tap(() => {
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Invitation emails have been successfully sent.",
          });
          this.getCompanyUsers();
          this.hideDialog();
          return of(null);
        }),
        catchError((error: HttpErrorResponse) => {
          this.errorMessage = error.message;
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: this.errorMessage,
          });
          return of(null);
        })
      )
      .subscribe();
  }

  viewUserDetails(user: any) {
    //Make sure selected users is cleared out upon dialog opening.
    this.selectedUsers = [];
    this.validateInvitationStatus(user);

    this.selectedUser = { ...user };
    this.selectedUsers.push(this.selectedUser);
    this.patchForm(user);
  }

  hideDialog() {
    this.clearSelectedUser();
    this.viewUserDetailsDialog = false;
  }

  clearSelectedUser() {
    this.selectedUsers = [];
    this.selectedUser = null;
  }

  saveUser() {
    console.log("User Saved!");
  }

  validateInvitationStatus(user: any) {
    const registrationCompleted =
      user.invitationStatus === "Accepted" &&
      (user.completionDate === "" || user.completionDate === null)

    this._validUserForNewInvitation.next(registrationCompleted);
  }

  onPageSizeChanged(value: string): void {
    this.gridApi.paginationSetPageSize(Number(value));
    this.gridApi.paginationGoToPage(0);
    this.localStorageService.getLocalStorage().setItem("gridPageSize", value);
  }

  getCompanyUsers() {
    this.adminService
      .getUsers(this.companyId, this.pageNumber, this.pageSize, this.sortBy)
      .pipe(
        tap((response: PaginatedResponse<GetUsersResponse>) => {
          if (response) {
            this.users = response.data;
            this.pageNumber = response.pageNumber;
            this.pageSize = response.pageSize;
            this.totalCount = response.totalCount;
          } else {
            this.users = [];
            this.pageNumber = 1;
            this.pageSize = 0;
            this.totalCount = 0;
          }
        })
      )
      .subscribe();
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;
      if (value1 == null && value2 != null) {
        result = -1;
      } else if (value1 != null && value2 == null) {
        result = 1;
      } else if (value1 == null && value2 == null) {
        result = 0;
      } else if (typeof value1 === "string" && typeof value2 === "string") {
        if (event.field === "rpLastConnected") {
          const date1 = moment(value1, "MM/DD/YYYY");
          const date2 = moment(value2, "MM/DD/YYYY");

          let result: number = -1;
          if (moment(date2).isBefore(date1, "day")) {
            result = 1;
          }

          return result * event.order;
        }
        result = value1.localeCompare(value2);
      } else {
        result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
      }
      return event.order * result;
    });
  }

  onSortChanged(): void {
    const sortModel = this.gridColumnApi.getColumnState();
    this.localStorageService.getLocalStorage().setItem("sortSetting", JSON.stringify(sortModel));
  }

  dateComparator(date1, date2) {
    const date1Number = date1 && new Date(date1).getTime();
    const date2Number = date2 && new Date(date2).getTime();

    if (date1Number == null && date2Number == null) {
      return 0;
    }

    if (date1Number == null) {
      return -1;
    } else if (date2Number == null) {
      return 1;
    }

    return date1Number - date2Number;
  }

  onCompanySelectionChanged(selectedCompanyId) {
    this.companyId = selectedCompanyId.value;
    this.getCompanyUsers();
  }

  deactivateUser() {
    // Not in use
    const userProfileId = 0;

    this.confirmService.confirm(
      "Warning",
      "Are you sure you want to make selected user inactive?",
      "pi pi-question-circle",
      () => {
        this.profileService
          .deactivateUser(userProfileId)
          .pipe(
            catchError((error: HttpErrorResponse) => {
              this.errorMessage = error.message;
              this.messageService.add({
                severity: "error",
                summary: "Error",
                detail: this.errorMessage,
              });
              return of(null);
            }),
            tap((profile: ManufacturerProfile) => {
              this.messageService.add({
                severity: "success",
                summary: "Success",
                detail: "User has been made inactive.",
              });

              //Temporary fix
              this.appState.updateProfile(profile);
              this.appState.setActiveProfiles();

              this.getCompanyUsers();
              this.hideDialog();
            })
          )
          .subscribe();
      }
    );
  }

  showClear(table: Table) {
    const isDirty =
      this.userForm.get("fullNameFilterValue").value ||
      this.userForm.get("companyNameFilterValue").value ||
      table._sortField;

    if (isDirty && !this._isClearVisible.value) {
      this._isClearVisible.next(true);
    }

    if (!isDirty && this._isClearVisible.value) {
      this._isClearVisible.next(false);
    }
  }

  clear(table: Table) {
    table.filters = {};

    // Reset to the default order
    table.sortField = "companyName";
    table.sortOrder = 1;
    table.sortSingle();

    table.reset();

    // Remove the sort icon
    table.sortOrder = 0;
    table.sortField = "";

    this.userForm.get("fullNameFilterValue")?.setValue("");
    this.userForm.get("companyNameFilterValue")?.setValue("");

    this._isClearVisible.next(false);
  }

  navigateToInviteUser() {
    return this.router.navigate(["/admin/invite-user"]);
  }

  getStatusBadgeColor(status: string): string {
    switch (status) {
      case 'Invalid':
      case 'Rejected':
      case 'Revoked':
      case 'Inactive':
        return 'danger';
      case 'Pending':
        return 'warning';
      case 'Accepted':
        return 'success';
      default:
        return 'danger'; // or any default color you want to use
    }
  }

  redirectTo(url: string, id?: string) {
    return this.router.navigate([`admin/${url}/${id ?? ''}`]);
  }
}
