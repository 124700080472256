import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { faEye, faEyeSlash, faWarning, faCheck } from "@fortawesome/free-solid-svg-icons";
import { MessageService } from "primeng/api";
import { ChangePasswordRequest } from "src/app/core/data/models/ChangePasswordRequest";
import { AppState } from "src/app/shared/services/app-state";
import { AuthenticationService } from "src/app/shared/services/authentication.service";
import { LocalStorageService } from "src/app/shared/services/local-storage.service";
import { ProfileService } from "src/app/shared/services/profile.service";

@Component({
  selector: "app-account-settings-security",
  templateUrl: "./security.component.html",
  styleUrls: ["./security.component.scss"],
})
export class SecurityComponent {
  showOldPassword: boolean = false;
  showNewPassword: boolean = false;
  showNewPasswordRepeat: boolean = false;

  faEyeSlash = faEyeSlash;
  faEye = faEye;
  faWarning = faWarning;
  faCheck = faCheck;
  changePasswordRequest: ChangePasswordRequest = new ChangePasswordRequest();
  passwordForm: FormGroup;

  constructor(private fb: FormBuilder,
    private profileService:ProfileService, 
    private authenticationService:AuthenticationService,
    private messageService:MessageService) {
    this.passwordForm = this.fb.group(
      {
        oldPassword: ["", Validators.required],
        newPassword: ["", [Validators.required, Validators.minLength(8), this.passwordStrengthValidator]],
        newPasswordRepeat: ["", Validators.required],
      },
      { validator: this.passwordsMatchValidator }
    );
  }

  passwordStrengthValidator(control: any) {
    const value = control.value;
    if (!value) return null;

    const hasUpperCase = /[A-Z]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);
    const hasNumber = /[0-9]/.test(value);

    const valid = hasUpperCase && hasLowerCase && hasNumber;
    return valid ? null : { passwordStrength: true };
  }

  passwordsMatchValidator(group: FormGroup) {
    const newPassword = group.get("newPassword")?.value;
    const newPasswordRepeat = group.get("newPasswordRepeat")?.value;
    return newPassword === newPasswordRepeat ? null : { passwordsMismatch: true };
  }

  isConditionMet(condition: string): boolean {
    const value = this.passwordForm.get("newPassword")?.value || "";
    switch (condition) {
      case "minLength":
        return value.length >= 8;
      case "hasLowerCase":
        return /[a-z]/.test(value);
      case "hasUpperCase":
        return /[A-Z]/.test(value);
      case "hasNumber":
        return /[0-9]/.test(value);
      default:
        return false;
    }
  }

  onSubmit() {
    if (this.passwordForm.valid) {
      let userData = this.authenticationService.claims();      
      this.changePasswordRequest.userId = userData.oid;      
      this.changePasswordRequest.newPassword = this.passwordForm.get("newPassword").value;
      this.profileService.changePassword(this.changePasswordRequest).subscribe(data=>{
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Password updated successfully.",
        });
      }, error =>{
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: error.error.error.detail ?? "An unexpected error occurred.",
        });
      })
    } else {      
     
    }
  }
}
