import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AuthContext } from "./authentication.service";
import { CookieService } from "ngx-cookie-service";
import { skipWhile, take } from "rxjs/operators";
import { Store } from "@ngxs/store";
import { IConfigs } from "src/app/core/data/models/iconfigs";
import { SpinnerService } from "src/app/core/spinner/spinner.service";
import {
  AdminContext,
  AuthStatus,
  ManufacturerProfile,
  ProfilePayee,
  ProfileStatus,
  RoofingWRXBranch,
  Service,
  ServiceStatus,
  ThirdPartyAuth,
  UserRole,
} from "src/app/core/data/models/AppInitializationData";
import { AppInitializationData, UserData } from "src/app/core/store/app.models";
import {
  faLayerGroup,
  faUserGroup,
  faBuilding,
  faListCheck,
  faPuzzlePiece,
  faHammer,
  faStore,
  faMagnifyingGlassChart,
  faChartSimple,
} from "@fortawesome/free-solid-svg-icons";
import { MenuItem } from "primeng/api";
import { Router } from "@angular/router";
import { Company } from "src/app/features/admin/admin-users/edit-user/edit-user.models";

@Injectable()
export class AppState {
  checkedLogin = false;
  loginAttempted = false;

  faLayerGroup = faLayerGroup;
  faUserGroup = faUserGroup;
  faBuilding = faBuilding;
  faListCheck = faListCheck;
  faPuzzlePiece = faPuzzlePiece;
  faHammer = faHammer;
  faStore = faStore;
  faChartSimple = faChartSimple;
  faMagnifyingGlassChart = faMagnifyingGlassChart;

  adminMenuItems: MenuItem[] = [];

  private appConfigs: IConfigs;

  private _appData: AppInitializationData = null;
  private _appData$ = new BehaviorSubject<AppInitializationData>(this._appData);
  private _profile: ManufacturerProfile = null;
  private _profile$ = new BehaviorSubject<ManufacturerProfile>(this._profile);
  private _companyId: number = +this.cookieService.get("manufacturer_id");
  private userSelected = new BehaviorSubject<any>(null);
  userSelection = this.userSelected.asObservable();
  private CompanySelected = new BehaviorSubject<Company>(null);
  CompanySelection = this.CompanySelected.asObservable();
  private clearFilters = new BehaviorSubject<any>(null);
  clearFilter = this.clearFilters.asObservable();
  //Temporary Fix
  private _activeProfiles = this._appData?.profiles;
  private _activeProfiles$ = new BehaviorSubject<ManufacturerProfile[]>(this._activeProfiles);

  constructor(
    private cookieService: CookieService,
    private spinner: SpinnerService,
    private store: Store,
    private router: Router
  ) {}

  get configs(): IConfigs {
    return this.appConfigs;
  }

  get activeProfiles() {
    return this._activeProfiles;
  }

  get activeProfiles$() {
    return this._activeProfiles$.pipe(skipWhile((i) => i == null));
  }

  get appData() {
    return this._appData;
  }

  get appData$() {
    return this._appData$.pipe(skipWhile((i) => i == null));
  }

  get appDataOnce$() {
    return this.appData$.pipe(take(1));
  }

  get profile$() {
    return this._profile$.pipe(skipWhile((i) => i == null));
  }

  get companyId() {
    return this._companyId;
  }

  get profiles(): ManufacturerProfile[] {
    return this._appData?.profiles || [];
  }

  get serviceStatus(): ServiceStatus[] {
    return this._appData?.serviceStatus || [];
  }

  get user(): UserData {
    return this._appData?.user;
  }

  get featureFlags(): Record<string, boolean>[] {
    return this._appData?.featureFlags || [];
  }

  get needsCurrentProfile(): boolean {
    return !this._companyId;
  }

  get managedManufacturers() {
    return this.profiles.filter((p) => p.role === UserRole.Admin).map((p) => p.manufacturer);
  }

  get authorizations(): ThirdPartyAuth[] {
    return this._profile?.authorizations ?? [];
  }

  get isAdminOrRPAdmin() {
    const role = this._appData?.user.roles;
    const adminContext = this._appData?.user.adminContext ?? AdminContext.Internal;

    return role?.some(
      (s) => (s === UserRole.RPAdmin || s === UserRole.Admin) && adminContext === AdminContext.Internal
    );
  }

  get isThirdPartyAdmin() {
    const isAdmin = this._appData?.user.roles.find((s) => s === UserRole.Admin);
    const adminContext = this._appData?.user.adminContext;

    return isAdmin && adminContext != null && adminContext !== AdminContext.Internal;
  }

  get adminContext() {
    return this._appData?.user.adminContext ?? AdminContext.Internal;
  }

  get isRPAdmin() {
    return (
      this._appData?.user.roles?.some((s) => s === UserRole.RPAdmin) && this.adminContext === AdminContext.Internal
    );
  }

  get roles() {
    return this.appData?.user.roles;
  }

  get canUseReporting() {
    return this._appData?.user.roles.some((s) => s === UserRole.RPAdmin || s === UserRole.Reporting);
  }

  get canUseAdminTasks() {
    return this._appData?.user.roles.some((s) => s === UserRole.RPAdmin || s === UserRole.PSAdmin);
  }

  get canManageCompanies() {
    return this._appData?.user.roles.some(
      (s) => s === UserRole.RPAdmin || s === UserRole.Reporting || s === UserRole.Admin
    );
  }

  get canManageUsers() {
    return this._appData?.user.roles.some(
      (s) => s === UserRole.RPAdmin || s === UserRole.Reporting || s === UserRole.Admin
    );
  }

  get canEditManufacturerSettings() {
    return this.isAdmin && !this.isThirdPartyAdmin;
  }

  get canViewAdminPanel() {
    return this._appData?.user.roles.some(
      (s) => s === UserRole.Admin || s === UserRole.RPAdmin || s === UserRole.Reporting || s === UserRole.PSAdmin
    );
  }

  get isAdmin() {
    return this.isAdminOrRPAdmin || this.isThirdPartyAdmin || this._profile?.role === UserRole.Admin;
  }

  get hasAcceptedTermsOfService(): boolean {
    return this.profiles.some((x) => x.status === ProfileStatus.Accepted);
  }

  get currentProfile() {
    return this._profile;
  }

  get profileManufacturer() {
    return [this.currentProfile.manufacturer];
  }

  get roofingWRXBranches(): RoofingWRXBranch[] {
    const auth = this.getAuthData(AuthContext.RoofingWRX);
    return auth?.claims?.branches ?? [];
  }

  get enabledPartnerSystems(): Record<Service, boolean> {
    return this._profile?.enabledPartnerSystems ?? {};
  }

  updateEnabledPartnerSystems(partnerSystems: Record<Service, boolean>) {
    this._profile.enabledPartnerSystems = partnerSystems;
    this._profile$.next(this._profile);
  }

  public selectedUser(user) {
    this.userSelected.next(user);
  }

  public initializeConfigs(data: IConfigs): void {
    if (!this.appConfigs) {
      this.appConfigs = data;
    }
  }

  public setAppData(data: AppInitializationData): AppInitializationData {
    this._appData = data;
    if (!this._appData.profiles) {
      this._appData.profiles = [];
    }

    this._appData.profiles.forEach((p) => {
      p.authorizations?.forEach((a) => {
        if (a.claims?.length == 0) {
          a.claims = [{ name: "email", label: "Email", value: "" }];
        }
      });
    });

    if (this.profiles.length == 1) {
      this.setProfile(this.profiles[0]?.userProfileId);
    } else if (this._profile?.userProfileId) {
      this.setProfile(this._profile?.userProfileId);
    }

    this._appData$.next(this._appData);
    this.spinner.hide();
    return this._appData;
  }

  public removeCurrentProfile() {
    this._profile = null;
    this.CompanySelected.next(null);
  }

  public setProfile(profileId: number): ManufacturerProfile {
    const profile = this.profiles.find((s) => s.userProfileId === profileId);
    if (profile) {
      this._companyId = profile.manufacturer.id;
      this.cookieService.set("manufacturer_id", this._companyId.toString());
    } else {
      this._companyId = null;
    }
    return profile;
  }

  public updateProfile(profile: ManufacturerProfile): ManufacturerProfile {
    if (this._appData) {
      const index = this.profiles.findIndex((p) => p.manufacturer.id == profile.manufacturer.id);
      if (index >= 0) {
        this.profiles.splice(index, 1, profile);
      } else {
        this.profiles.push(profile);
      }

      if (profile.manufacturer.id == this._companyId) {
        this._profile = profile;
        this._profile$.next(profile);
      }
    }

    return profile;
  }

  public updateAuth(auth: ThirdPartyAuth): ThirdPartyAuth {
    if (this._profile) {
      const index = this._profile.authorizations.findIndex((a) => a.context == auth.context);
      if (index >= 0) {
        this._profile.authorizations.splice(index, 1, auth);
      } else {
        this._profile.authorizations.push(auth);
      }
    }
    return auth;
  }

  public getAuthData(context: AuthContext): ThirdPartyAuth {
    return (
      this.authorizations?.find((auth) => auth.context === context) || {
        context: AuthContext.None,
        status: AuthStatus.None,
        claims: {},
        rejected: false,
      }
    );
  }

  /**
   * Reset auth data for the current profile by context
   * @param context The 3rd party auth context
   */
  public expireAuthData(context: AuthContext) {
    const auth = this.authorizations.find((i) => i.context === context);
    if (auth) {
      auth.status = AuthStatus.Expired;
    }
  }

  public hasNewRegistration() {
    var hasNewRegistration: boolean = false;
    var profile: ManufacturerProfile = this.profiles.find((x) => x.status === ProfileStatus.Pending);

    if (profile) {
      this.setProfile(profile.userProfileId);
      hasNewRegistration = true;
    }

    return hasNewRegistration;
  }

  public setActiveProfiles() {
    this._activeProfiles = this.profiles.filter((x) => x.status !== ProfileStatus.Inactive);
    this._activeProfiles$.next(this._activeProfiles);
  }

  redirectToAdminPanel() {
    let url = "/admin" + this.adminMenuItems[0].url;
    this.router.navigate([url]);
  }

  redirectToUsers() {
    let url = "/admin/users";
    this.router.navigate([url]);
  }

  redirectToSelectCompany() {
    let url = "/select-company";
    this.router.navigate([url]);
  }

  public setAdminMenuItems() {
    if (this.adminMenuItems.length === 0) {
      // Work queue
      if (this.canUseAdminTasks) {
        this.adminMenuItems.push({
          label: "Work queue",
          routerLink: "./work-queue",
          url: "/work-queue",
          iconStyle: faLayerGroup,
        });
      }

      // Companies (RP Admin)
      if (this.canEditManufacturerSettings) {
        this.adminMenuItems.push({
          label: "Companies",
          routerLink: "./companies",
          url: "/companies",
          iconStyle: faBuilding,
        });
      }

      // Users
      if (this.canManageUsers) {
        this.adminMenuItems.push({
          label: "Users",
          routerLink: "./users",
          url: "/users",
          iconStyle: faUserGroup,
        });
      }

      // KPI Reporting (RP Admin)
      if (this.canUseReporting) {
        this.adminMenuItems.push({
          label: "KPI Reporting",
          routerLink: "./reporting",
          url: "/reporting",
          iconStyle: faChartSimple,
        });
      }

      // // Research (RP Admin)
      // this.adminMenuItems.push({
      //   label: "Research",
      //   routerLink: "./",
      //   url: '/',
      //   iconStyle: faMagnifyingGlassChart,
      // });
    }
    return this.adminMenuItems;
  }

  selectedCompany(company: Company) {
    this.CompanySelected.next(company);
  }

  ClearFilters(clear: boolean) {
    this.clearFilters.next(clear);
  }
}
